import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'

export const componentState = () => {
  const { getters } = useStore()
  const state = reactive({
    tabIndex: computed(() => getters['cs/getTabIndex'])
  })

  return { ...toRefs(state) }
}
